import React from "react"
import { useStaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            frontmatter {
              title
              description
              path
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges
    .map(({ node }) => {
      return node.frontmatter
    })
    .map(post => {
      return (
        <div key={post.title} className="pb-6">
          <Link to={post.path}>
            <div className=" text-sm">{post.date}</div>
            <h2 className="mb-2 mt-0 hover:underline ">{post.title}</h2>
            <p>{post.description}</p>
          </Link>
        </div>
      )
    })

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="max-w-2xl mx-auto">
        <div>{posts}</div>
      </div>
    </Layout>
  )
}

export default IndexPage
